<template>
  <div v-if="event">
    <h1>{{ event.title }}</h1>
    <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
    <p>{{ event.description }}</p>
  </div>
</template>

<script>
import EventService from "../services/EventService";

export default {
  name: "EventDetails",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      event: null
    };
  },
  created() {
    EventService.getEventById(this.id)
      .then(res => (this.event = res.data))
      .catch(err => console.log(err));
  }
};
</script>
